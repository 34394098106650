
<template xmlns:src="http://www.w3.org/1999/xhtml" xmlns:v-bind="http://www.w3.org/1999/xhtml"
          xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="col s12 no-padding">


        <div v-if="mustRefreshCustomer" class="col s12 no-padding">
            <div class="col s12">
                <div class="col s6 m3 l2 offset-s3 offset-m6 offset-l5 vert-offset-top-3">
                    <button v-if="!isProcessing"
                            class="btn btn-block homeboy-gold-text homeboy-charcoal-black"
                            @click.prevent="getCustomerInfo()">
                        Reload
                    </button>
                </div>
            </div>

        </div>

        <div v-if="!mustRefreshCustomer" class="card col s12 vert-offset-top-1 padding-top-half">
            <div v-if="hasCustomer" class="row col s12 vert-offset-top-1">
                <div class="card-content col s12">
                    <div class="col s12 padding-bottom-1">
                        <div class="col s12 padding-bottom-half">
                            <span class="font-bold">{{ customer.name }}</span>
                        </div>
                    </div>
                    <div v-if="hasAccount" class="col s12">
                        <div class="col s12 font-bold font-20 padding-bottom-1 grey-border border-bottom-1 border-lighten-2">
                            Account Info
                        </div>
                        <div class="col s12 m6 padding-top-half">
                            <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                                <div class="col s12 no-padding">
                                    <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                        <span>Account &#35;</span>
                                        <a @click.prevent="toastDescription(description.account_number)"
                                           :title="description.account_number"
                                           class="clickable margin-left-half">
                                            <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                        </a>
                                    </div>
                                    <div class="col s8 m6 font-bold text-align-right font-12">
                                        <span>{{ account.number}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m6 padding-top-half">
                            <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                                <div class="col s12 no-padding">
                                    <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                        <span>Mobile &#35;</span>
                                        <a @click.prevent="toastDescription(description.mobile)"
                                           :title="description.mobile"
                                           class="clickable margin-left-half">
                                            <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                        </a>
                                    </div>
                                    <div class="col s8 m6 font-bold text-align-right font-12">
                                        <span>{{ customer.mobile}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m6 padding-top-half padding-bottom-half font-16">
                            <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                                <div class="col s12 no-padding font-10">
                                    <div class="col s4 m6 grey-text text-darken-2">&nbsp;</div>
                                    <div class="col s8 m6 font-bold text-align-right">
                                        <span>{{country.currency.code}}</span>
                                    </div>
                                </div>
                                <div class="col s12 no-padding">
                                    <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                        <span>Available Amount <a @click.prevent="toastDescription(description.credit)"
                                                                  :title="description.credit"
                                                                  class="clickable margin-left-half">
                                            <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                        </a></span>

                                    </div>
                                    <div class="col s8 m6 font-bold text-align-right font-20">
                                        <span>{{ account.credit}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m6 padding-top-half padding-bottom-half font-16">
                            <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                                <div class="col s12 no-padding font-10">
                                    <div class="col s4 m6 grey-text text-darken-2">&nbsp;</div>
                                    <div class="col s8 m6 font-bold text-align-right">
                                        <span>{{country.currency.code}}</span>
                                    </div>
                                </div>
                                <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                    <span>Amount Owed<a @click.prevent="toastDescription(description.balance)"
                                                        :title="description.balance"
                                                        class="clickable margin-left-half">
                                        <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                    </a></span>

                                </div>
                                <div class="col s8 m6 font-bold text-align-right font-20">

                                    {{ account.balance}}
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m6 padding-top-half" v-if="account.min_payment_amount > 0">
                            <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                                <div class="col s12 no-padding">
                                    <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                        <span>Minimum Payment</span>
                                        <a @click.prevent="toastDescription(description.min_payment)"
                                           :title="description.min_payment"
                                           class="clickable margin-left-half">
                                            <i class="material-icons tiny homeboy-gold-text">info_outline</i>
                                        </a>
                                    </div>
                                    <div class="col s8 m6 font-bold text-align-right font-12">
                                        <span>{{ account.min_payment_amount}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m6 padding-top-half" v-if="account.min_payment_amount > 0">
                            <div class="col s12 padding-top-1 padding-bottom-1 grey-border border-1 border-lighten-3">
                                <div class="col s12 no-padding">
                                    <div class="col s4 m6 grey-text text-darken-2 border-right-1 grey-border border-lighten-3">
                                        <span>Min. Payment Due Date</span>

                                    </div>
                                    <div class="col s8 m6 font-bold text-align-right font-12">
                                        <span>{{ account.min_payment_date}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col s12 no-padding vert-offset-top-2">
                            <tab-component>
                                <li id="report-link-transactions" class="tab active">
                                    <a  href="#report-tab-transactions">Transactions</a>
                                </li>
                                <li id="report-link-invoices" class="tab">
                                    <a  href="#report-tab-invoices">Statements</a>
                                </li>
                            </tab-component>



                            <div id="report-tab-transactions" class="col s12 no-padding" v-if="hasTransactions">
                                <div class="col s12 font-bold font-16 vert-offset-top-1 padding-bottom-1 grey-border border-bottom-1 border-lighten-2" >
                                    Transactions
                                </div>
                                <div class="col s12 padding-top-1">
                                    <table class="font-10">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>Amount ({{country.currency.code}})</th>
                                            <th>Type</th>
                                            <th>Outlet</th>
                                            <th>Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(_item, _index) in transactions" :key="_index">
                                            <td>{{ _index + 1}}</td>
                                            <td>{{ _item.amount }}</td>
                                            <td>{{ _item.type.data.name }}</td>
                                            <td>{{ findOutletName(_item) }}</td>
                                            <td>{{ _item.date }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col s12 vert-offset-top-half padding-top-1 grey-border border-bottom-1 border-lighten-2 center" v-if="hasTransactions">
                                    <button
                                            v-if="showNext && downloaded"
                                            class="btn btn-tiny gold-text black"
                                            @click.prevent="getListTransactions()"
                                    >More</button>

                                    <button
                                            v-if="downloaded && hasTransactions && !showNext"
                                            class="btn btn-tiny gold-text black"
                                            @click.prevent="resetListTransactions()"
                                    >Refresh</button>
                                </div>
                            </div>

                            <div id="report-tab-invoices">
                                <account-invoices-food-credit-component></account-invoices-food-credit-component>
                            </div>
                        </div>


                    </div>

                </div>


            </div>
        </div>

    </div>
</template>
<script>
    // @ is an alias to /src
    import {mapState} from "vuex";
    import M from "materialize-css";
    import AccountInvoicesFoodCreditComponent from "@/components/customers/services/food-credit/listInvoices.vue";
    import TabComponent from "@/components/materialize/TabComponent.vue";
    /*import moment from 'moment';*/


    export default {
        name: "HomeFoodCreditComponent",
        components:{
            AccountInvoicesFoodCreditComponent, TabComponent
        },
        data() {
            return {
                arr_outlets: [],
                arr_devices: [],
                description: {
                    balance: "The amount owed represents how much you currently owe.",
                    credit: "The available amount represents the amount available to spend.",
                    account_number: "Account Number",
                    mobile: "Mobile Number",
                    penalty: "Penalty charge if payment is not made on time",
                    penalty_charge: "Penalty charged if payment is not made on time",
                    min_payment:"You need to recharge your account with at least this amount before MIN. PAYMENT DUE DATE.The penalty for non payment before due date is BWP ",
                },
                downloaded: false,
                pagination: null,
                links: null,
                triggerTooltip:false,
            }
        },
        created () {

        },
        mounted() {
            this.getCustomerInfo();

        },
        methods : {
            findOutletName: function(_transaction)
            {
                let _arrFound = this.arr_devices.filter(function(_item)
                {
                    return (_item.checkin === _transaction.checkin);
                });
                if(typeof _arrFound !== "undefined" && _arrFound !== null && _arrFound.length>0)
                {
                    let _objFound = _arrFound[0];
                    _arrFound = this.arr_outlets.filter(function(_item)
                    {
                        return (_item.device_outlet === _objFound.device_outlet);
                    });
                    if(typeof _arrFound !== "undefined" && _arrFound !== null && _arrFound.length>0)
                    {
                        return _arrFound[0].outlet.data.name;
                    }
                }
                return "N/A";
            },
            toastDescription : function(_description)
            {
                if(typeof _description !== "undefined" && _description !== null && _description.length > 0)
                {
                    M.toast({html: "<span class='font-12'>"+_description+"</span>"});
                }
            },
            getCustomerInfo: function()
            {
                console.log("getCustomerInfo");
                let _thisObj = this;

                this.$store.dispatch('FC_GET_CUSTOMER_EXISTS', {
                    msisdn: this.msisdn,
                    country: this.country.key
                }).then(() => {
                    _thisObj.getListTransactions();
                });
            },
            scroll ()
            {
                let _thisObj = this;
                window.onscroll = () =>
                {
                    /*window.console.log("ScrollTop: "+ document.documentElement.scrollTop);*/
                    let _refresh = null;
                    if((document.documentElement.scrollTop === 0) && (_thisObj.scrollPoint > 0))
                    {
                        if(_thisObj.new_content)
                        {
                            _refresh = true;
                        }
                    }
                    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                    if ((bottomOfWindow && _thisObj.showNext)||_refresh === true)
                    {
                        _thisObj.getList(_refresh);
                    }
                    _thisObj.scrollPoint = document.documentElement.scrollTop;
                };
            },
            resetListTransactions: function()
            {
                window.scrollTo(0, 0);
                this.pagination = null;
                this.links = null;
                this.getListTransactions({});
            },
            getListTransactions: function(_formData)
            {
                let _thisObj = this;
                if(_thisObj.hasAccount)
                {
                    if(typeof _formData === "undefined" || _formData === null)
                    {
                        _formData = {
                            page: _thisObj.next_page
                        };
                    }
                    window.console.log("Transactions formData: " +JSON.stringify(_formData));
                    _thisObj.$store.dispatch('FC_GET_CUSTOMER_REPORT',_formData)
                        .then(function(_response)
                        {
                            if(typeof _response.data.extra !== "undefined" && _response.data.extra !== null)
                            {
                                let _i = 0;
                                if(typeof _response.data.extra.outlets !== "undefined" && _response.data.extra.outlets !== null && _response.data.extra.outlets.length > 0)
                                {
                                    for(_i=0;_i<_response.data.extra.outlets.length;_i++)
                                    {
                                        if(_thisObj.arr_outlets.indexOf(_response.data.extra.outlets[_i]))
                                        {
                                            _thisObj.arr_outlets.push(_response.data.extra.outlets[_i]);
                                        }
                                    }
                                }
                                if(typeof _response.data.extra.devices !== "undefined" && _response.data.extra.devices !== null && _response.data.extra.devices.length > 0)
                                {
                                    for(_i=0;_i<_response.data.extra.devices.length;_i++)
                                    {
                                        if(_thisObj.arr_devices.indexOf(_response.data.extra.devices[_i]))
                                        {
                                            _thisObj.arr_devices.push(_response.data.extra.devices[_i]);
                                        }
                                    }
                                }

                            }
                            /*window.console.log("START-CUSTOMER REPORT----");
                             window.console.log(JSON.stringify(_response));
                             window.console.log("END-CUSTOMER REPORT----");*/

                            _thisObj.downloaded = true;
                            if(typeof _response.data.pagination !== "undefined" && _response.data.pagination !== null)
                            {
                                _thisObj.pagination = _response.data.pagination;
                            }
                            if(typeof _response.data.links !== "undefined" && _response.data.links !== null)
                            {
                                _thisObj.links = _response.data.links;
                            }
                            /*_thisObj.$store.dispatch('SET_TRIGGER_TOOLTIP',true);*/
                        });
                }
                else
                {
                    window.console.log("No Account Data Found");
                }
            },
        },
        computed: {
            ...mapState({
                customer:state=>state.foodcredit.customer,
                account:state=>state.foodcredit.account,
                activated:state=>state.foodcredit.active,
                confirmed:state=>state.foodcredit.confirmed,
                selected_service:state=>state.customers.customer_selected_service,
                msisdn: state => state.customers.msisdn,
                processing: state => state.processing,
                country: state => state.customers.country,
                transactions: state => state.foodcredit.paginated_list_transactions,
            }),
            hasMSISDN: function()
            {
                return (typeof this.msisdn !== "undefined" && this.msisdn !== null && this.msisdn.length > 0);
            },
            isProcessing: function()
            {
                return (typeof this.processing !== "undefined" && this.processing !== null && this.processing > 0);
            },
            hasCustomer: function()
            {
                return (typeof this.customer !== "undefined" && this.customer !== null);
            },
            hasAccount: function()
            {
                return (this.hasCustomer && typeof this.account !== "undefined" && this.account !== null);
            },
            hasTransactions: function()
            {
                return (this.hasAccount && typeof this.transactions !== "undefined" && this.transactions !== null && this.transactions.length > 0);
            },
            isConfirmed: function()
            {
                return (this.hasCustomer && typeof this.confirmed !== "undefined" && this.confirmed !== null && this.confirmed === true);
            },
            isActivated: function()
            {
                return (this.hasCustomer && typeof this.activated !== "undefined" && this.activated !== null && this.activated === true);
            },
            mustRefreshCustomer: function()
            {
                return (!this.isProcessing && !this.hasCustomer);
            },
            current_page: function()
            {
                if(!this.hasTransactions)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.current_page === "undefined" || this.pagination.current_page === null)
                {
                    return null;
                }
                return this.pagination.current_page;
            },
            total_pages: function()
            {
                if(!this.hasTransactions)
                {
                    return null;
                }
                if(typeof this.pagination === "undefined" || this.pagination === null)
                {
                    return null;
                }
                if(typeof this.pagination.total_pages === "undefined" || this.pagination.total_pages === null)
                {
                    return null;
                }
                return this.pagination.total_pages;
            },
            next_page: function()
            {
                if(typeof this.current_page === "undefined" || this.current_page === null)
                    return null;

                if(typeof this.total_pages === "undefined" || this.total_pages === null)
                    return null;

                if(this.total_pages <= this.current_page)
                    return null;

                return (this.current_page+1);
            },
            showNext:function ()
            {
                return (typeof this.next_page !== "undefined" && this.next_page !== null);
            },
        },


        watch: {
        }
    };
</script>